import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import { http } from "store/actions/http";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetUserReviewReport } from "store/actions/reports/user_review/getUserReviewReport";
import { actionClearUserReviewFilters } from "store/actions/reports/user_review/clearUserReviewFilters";
import { actionGenerateUserReviewExcel } from "store/actions/reports/user_review/generateUserReviewExcel";
import { actionSetUserReviewFilters } from "store/actions/reports/user_review/setUserReviewFilters";
import { actionSetSelectedUserReviewFilters } from "store/actions/reports/user_review/setSelectedUserReviewFilters";
import { actionShowProgress } from "store/actions/general/progress";

import { useLang } from "hooks/lang";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import Page from "./page";
import { actionGetUsers } from "store/actions/user/getUsers";

function UserReviewReport({ ...rest }) {
  const t = useLang();
  const query = useLocation();
  const [data, setData] = useState({});
  const [chartData, setChartData] = useState({});
  const [userReviewData, setUserReviewData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openTireDialog, setOpenTireDialog] = useState(false);
  const [vehicleReviewId, setVehicleReviewId] = useState(null);
  const [incompletedVehicles, setIncompletedVehicles] = useState([]);
  const [openVehicleDialog, setOpenVehicleDialog] = useState(false);

  const [discardedReviews, setDiscardedReviews] = useState([]);
  const [showDiscardedReviews, setShowDiscardedReviews] = useState(false);

  const {
    account,
    workArea,
    filter,
    selectedFilter,
    setNotification,
    getUserReviewReport,
    clearUserReviewFilters,
    generateUserReviewExcel,
    setUserReviewFilters,
    setSelectedFilters,
    showProgress,
    getUsers,
  } = rest;

  async function fetchUserReviewData() {
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/user/review/summary`,
        params: {
          ...filter,
        },
      });
      return response;
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }

  async function handleClickVehicleType(data) {
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/user/review/details`,
        params: {
          corporate_name: data.corporate,
          company_name: data.company,
          subsidiary_name: data.subsidiary,
          division_name: data.division,
          date_from: filter.date_from,
          date_to: filter.date_to,
          user_name: data.user,
          vehicle_type_name: data.vehicleType,
          with_mount_dismount: filter.with_mount_dismount,
          ...filter,
        },
      });
      setData((s) => {
        const newData = JSON.parse(JSON.stringify(s))["review"];
        const existingData =
          newData[data.corporate][data.company][data.subsidiary][data.division][
            data.user
          ][data.vehicleType];
        newData[data.corporate][data.company][data.subsidiary][data.division][
          data.user
        ][data.vehicleType] = {
          ...existingData,
          ...response,
        };
        return {
          review: newData,
        };
      });
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setShowDiscardedReviews(false);
        const response = await getUserReviewReport({ ...filter });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchUserReviewData();
        setUserReviewData(response?.review);

        const badReviews = response?.badReviews.map((vehicle) => ({
          vehicle_id: vehicle.vehicle_id,
          economic_number: vehicle.economic_number,
          cause: "bad",
          date: vehicle.review_date,
          subsidiary: vehicle.subsidiary,
          user_name: vehicle.user_name,
        }));
        const emptyReviews = response?.emptyReviews.map((vehicle) => ({
          vehicle_id: vehicle.vehicle_id,
          economic_number: vehicle.economic_number,
          cause: "empty",
          date: vehicle.review_date,
          subsidiary: vehicle.subsidiary,
          user_name: vehicle.user_name,
        }));

        let discardedReviews = badReviews.concat(emptyReviews);

        setDiscardedReviews(discardedReviews);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (Object.keys(filter).length > 0) {
      getIncompletedVehicles({ ...filter });
    }
  }, [filter]);

  useEffect(() => {
    async function setupFilters() {
      try {
        const corporate = getCorporateViaWorkArea(account, workArea);
        const companies = getCompanyViaWorkArea(account, workArea);

        const startDate = moment().startOf("month").format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");

        const filter = {
          corporate_id: corporate.corporate_id.toString(),
          companies: companies.map((company) => company.company_id).join(),
          date_from: startDate,
          date_to: endDate,
        };

        const selectedFilters = {
          corporate: corporate.corporate.name,
          companies: companies.map((company) => company.company.name).join(),
          date_from: startDate,
          date_to: endDate,
        };

        const params = new URLSearchParams(query.search);
        if (params.has("date")) {
          const date = moment(params.get("date"));
          const startDate = date.startOf("month").format("YYYY-MM-DD");
          const endDate = date.endOf("month").format("YYYY-MM-DD");

          if (params.has("user")) {
            let data = await getUsers(
              {
                scope: "user_id,name,last_name_1,last_name_2",
              },
              false
            );

            data = data.map((user) => ({
              ...user,
              name: `${user.name} ${user.last_name_1} ${
                user.last_name_2 ?? ""
              }`.trim(),
            }));
            const foundUser = data.find(
              (user) => user.name === params.get("user")
            );
            filter.users = foundUser?.user_id?.toString();

            selectedFilters.users = params.get("user");
          }

          const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
          const subsidiary = getSubsidiaries(account.subsidiaries)
            .filter(
              (subsidiary) =>
                subsidiary.company_id === subsidiaries[0].subsidiary.company_id
            )
            .find((subsidiary) => subsidiary.name === params.get("subsidiary"));

          filter.date_from = startDate;
          filter.date_to = endDate;
          selectedFilters.date_from = startDate;
          selectedFilters.date_to = endDate;
          if (subsidiary) {
            filter.subsidiaries = subsidiary.subsidiary_id.toString();
            selectedFilters.subsidiaries = subsidiary.name;
          }
        }

        setUserReviewFilters(filter);
        setSelectedFilters(selectedFilters);
      } catch (error) {
        setNotification(error, true);
      }
    }

    setupFilters();
    return () => {
      clearUserReviewFilters();
    };
  }, []);

  useEffect(() => {
    setChartData(getChartData(data));
  }, [data]);

  async function getIncompletedVehicles(filter) {
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/user/review/vehicles/without/tires`,
        params: {
          ...filter,
        },
      });
      setIncompletedVehicles(response);
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateUserReviewExcel() {
    try {
      await generateUserReviewExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleCloseTireDialog() {
    setOpenTireDialog(false);
    setVehicleReviewId(null);
  }

  function handleOpenTireDialog(vehicleReviewId) {
    setOpenTireDialog(true);
    setVehicleReviewId(vehicleReviewId);
  }

  function handleOpenVehicleDialog() {
    setOpenVehicleDialog(true);
  }

  function handleCloseVehicleDialog() {
    setOpenVehicleDialog(false);
  }

  return (
    <Page
      t={t}
      data={getData(data)}
      chartData={chartData}
      userReviewData={userReviewData}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateUserReviewExcel={handleGenerateUserReviewExcel}
      handleClickVehicleType={handleClickVehicleType}
      openTireDialog={openTireDialog}
      handleOpenTireDialog={handleOpenTireDialog}
      handleCloseTireDialog={handleCloseTireDialog}
      vehicleReviewId={vehicleReviewId}
      incompletedVehicles={incompletedVehicles}
      openVehicleDialog={openVehicleDialog}
      handleOpenVehicleDialog={handleOpenVehicleDialog}
      handleCloseVehicleDialog={handleCloseVehicleDialog}
      discardedReviews={discardedReviews}
      showDiscardedReviews={showDiscardedReviews}
      setShowDiscardedReviews={setShowDiscardedReviews}
    />
  );
}
UserReviewReport.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.UserReview.filter,
  selectedFilter: state.Reports.UserReview.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getUserReviewReport: actionGetUserReviewReport(dispatch),
  clearUserReviewFilters: actionClearUserReviewFilters(dispatch),
  setUserReviewFilters: actionSetUserReviewFilters(dispatch),
  setSelectedFilters: actionSetSelectedUserReviewFilters(dispatch),
  generateUserReviewExcel: actionGenerateUserReviewExcel(dispatch),
  showProgress: actionShowProgress(dispatch),
  getUsers: actionGetUsers(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserReviewReport);

function getData(data) {
  if (Object.keys(data).length > 0) {
    return data.review;
  }

  return {};
}

function getChartData(data) {
  if (Object.keys(data).length > 0) {
    const chartData = {};

    Object.keys(data.review).forEach((corporate) => {
      Object.keys(data.review[corporate])
        .filter((key) => !["statistics", "percent"].includes(key))
        .forEach((company) => {
          chartData[corporate] = {};
          Object.keys(data.review[corporate][company])
            .filter((key) => !["statistics", "percent"].includes(key))
            .forEach((subsidiary) => {
              chartData[corporate][subsidiary] = {
                title: subsidiary,
                labels: [],
                datasets: [
                  {
                    backgroundColor: [],
                    borderColor: [],
                    data: [],
                    borderWidth: 1,
                  },
                ],
              };

              const result = Object.keys(
                data.review[corporate][company][subsidiary]
              )
                .filter((key) => !["statistics", "percent"].includes(key))
                .reduce((acc, division) => {
                  Object.keys(
                    data.review[corporate][company][subsidiary][division]
                  )
                    .filter((key) => !["statistics", "percent"].includes(key))
                    .forEach((user) => {
                      if (acc[user] === undefined) {
                        acc[user] = 0;
                      }
                      acc[user] +=
                        data.review[corporate][company][subsidiary][division][
                          user
                        ]["statistics"];
                    });

                  return acc;
                }, {});
              Object.entries(result).map(([user, statistics]) => {
                chartData[corporate][subsidiary].labels.push(user);
                const percent =
                  (statistics * 100) /
                  data.review[corporate][company][subsidiary]["statistics"];
                chartData[corporate][subsidiary].datasets[0].data.push({
                  percent,
                  value: statistics,
                });
                const [red, green, blue] = getRandomColor();
                chartData[corporate][subsidiary].datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                chartData[corporate][
                  subsidiary
                ].datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue}, 0.55)`
                );
              });
            });
        });
    });

    return chartData;
  }

  return {};
}

function getRandomColor() {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  return [red, green, blue];
}

function getSubsidiaries(assignedSubsidiaries) {
  return assignedSubsidiaries.map(
    (assignedSubsidiary) => assignedSubsidiary.subsidiary
  );
}
